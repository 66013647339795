import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Main from './pages/Main';
import Signup from './pages/Signup';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Subscription from './pages/Subscription';
import SubscriptionConfirmation from './pages/SubscriptionConfirmation';
import SubscriptionCanceled from './pages/SubscriptionCanceled';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/signup" component={Signup} />
      <Route path="/contact" component={Contact} />
      <Route
        path="/subscriptionCanceled"
        isPrivate
        component={SubscriptionCanceled}
      />

      <Route path="/dashboard" isPrivate component={Dashboard} />
      <Route path="/subscription" isPrivate component={Subscription} />
      <Route
        path="/subscriptionConfirmation"
        isPrivate
        component={SubscriptionConfirmation}
      />
    </Switch>
  );
}
