import { combineReducers } from 'redux';

import auth from './auth';
import content from './content';
import active from './active';

export default combineReducers({
  auth,
  content,
  active,
});
