import styled, { css } from 'styled-components';
import { primaryColor } from '../../styles/vars';

export const Box = styled.div`
  background: #f7f7f7;
  overflow: hidden;
  .active {
    background: ${primaryColor};
  }
`;

export const BoxDarker = styled.div`
  background: #f2f2f2;
  overflow: hidden;
`;

export const Link = styled.a`
  padding: 10px;
  display: block;
  font-weight: bold;
  font-size: 15px;
  :hover {
    background: ${primaryColor};
    color: #fff !important;
  }

  :active {
    background: ${primaryColor};
    color: #fff !important;
  }

  ${props =>
    props.loading
      ? css`
          .item {
            background-color: #99be5b;
          }
        `
      : undefined}
`;

export const LinkCategory = styled.a`
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  font-weight: bold;
  font-size: 15px;
  :hover {
    background: ${primaryColor};
    color: #fff !important;
  }
`;

export const Icon = styled.img`
  flex: 1;
  margin-right: 15px;
  width: 20px;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #777;
  padding: 12px;
`;
