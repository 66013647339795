import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Link, LinkCategory, Icon, BoxDarker, Title } from './styles';
import iconProfile from '../../assets/img/icon-profile.png';
import iconRecentes from '../../assets/img/icon-recentes.png';
import iconRevistas from '../../assets/img/icon-revistas.png';
import iconJornais from '../../assets/img/icon-jornais.png';
import iconLivros from '../../assets/img/icon-livros.png';
import iconFavoritos from '../../assets/img/icon-favoritos.png';
import { setActive } from '../../store/modules/active';
import { loadCategoryContent } from '../../store/modules/content';
import { SearchContext } from '../../services/searchContex';
import { ProfileContext } from '../../services/profileContext';

export default function SideMenu() {
  const [
    text,
    setText,
    searchResult,
    setSearchResult,
    loading,
    setLoading,
  ] = useContext(SearchContext);
  const [
    profile,
    setProfile,
    data,
    setData,
    loadingProfile,
    setLoadingProfile,
  ] = useContext(ProfileContext);
  const { categories } = useSelector(state => state.content);
  const { activeRouter } = useSelector(state => state.active);
  const dispatch = useDispatch();

  const links = [
    { routerName: 'Perfil', icon: iconProfile, to: '', active: 'false' },
    { routerName: 'Destaques', icon: iconFavoritos, to: '', active: 'false' },
    { routerName: 'Recentes', icon: iconRecentes, to: '', active: 'false' },
    { routerName: 'Revistas', icon: iconRevistas, to: '', active: 'false' },
    { routerName: 'Livros', icon: iconLivros, to: '', active: 'false' },
    { routerName: 'Jornais', icon: iconJornais, to: '', active: 'false' },
    { routerName: 'Favoritos', icon: iconFavoritos, to: '', active: 'false' },
  ];

  function setActivetedRouter(itemName) {
    if (itemName !== 'Profile') {
      setProfile(false);
    }
    dispatch(setActive(itemName));
  }
  function getCategory(category) {
    setActivetedRouter(category.name);
    dispatch(loadCategoryContent(category.id));
  }

  return (
    <>
      <Box>
        {links.map((link, index) => {
          return (
            <Link
              className={link.routerName === activeRouter ? 'active' : ''}
              key={String(index)}
              onClick={() => {
                setActivetedRouter(link.routerName);
                setText('');
              }}
              to={link.to}
            >
              <Icon src={link.icon} />
              {link.routerName}
            </Link>
          );
        })}
      </Box>
      <BoxDarker>
        <Title>Categorias</Title>
        {categories.map(category => {
          return (
            <LinkCategory
              className={category.name === activeRouter ? 'active' : ''}
              key={category.id}
              onClick={() => {
                getCategory(category);
                setText('');
              }}
            >
              {category.name}
            </LinkCategory>
          );
        })}
      </BoxDarker>
    </>
  );
}
