import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    p {
      position: initial;
      margin-bottom: 0px;
      color: #99be5b;
      font-weight: bold;
      font-size: 15px;
    }
  }
  .search {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-right: 22%;

    padding: 5px 5px;
    background-color: #f2f2f2;

    border-radius: 3px;

    -webkit-box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);
    -moz-box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);
    box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);

    input {
      padding: 0px 5px;
      background-color: transparent;
      border: none;
    }
  }
  margin-bottom: 30px;
`;

export const ListProducts = styled.div`
  .header-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .search {
      display: flex;
      flex-direction: row;
      align-items: center;
      input {
        height: 20px;
      }
      button {
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
`;

export const CardItem = styled.div`
  color: black;
  max-width: 135px;
  display: inline-flex;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 30px;
  cursor: pointer;

  img {
    width: 135px;
    height: 180px;
  }

  p {
    font-weight: 500;
    border-top: 4px solid #e4eedb;
    padding-top: 2px;
  }

  span {
    margin-top: -20px;
    color: #99be5b;
    font-size: 13px;
  }
`;
