import produce from 'immer';

// Types
export const Types = {
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SET_USER: '@auth/SET_USER',
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',
  SIGN_UP_REQUEST: '@auth/SIGN_UP_REQUEST',
  SIGN_UP_FAILURE: '@auth/SIGN_UP_FAILURE',
  LOGOUT: '@aut/LOGOUT',
  SET_LOADING: '@auth/SET_LOADING',
  SET_SUBSCRIPTION: '@auth/SET_SUBSCRIPTION',
  VALIDATE_SUBSCRIPTION: '@auth/VALIDATE_SUBSCRIPTION',
  SUBSCRIPTION_IN_REQUEST: '@auth/SUBSCRIPTION_IN_REQUEST',
  SUBSCRIPTION_IN_FAILURE: '@auth/SUBSCRIPTION_IN_FAILURE',
  SUBSCRIPTION_CANCEL: '@auth/SUBSCRIPTION_CANCEL',
};

// Reducers
const INITIAL_STATE = {
  token: null,
  user: {},
  failure: false,
  failureSignup: false,
  loading: false,
  subscription: false,
  failureSubscription: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SIGN_IN_SUCCESS:
      return produce(state, draft => {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
      });
    case Types.SIGN_IN_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case Types.SIGN_UP_FAILURE:
      return {
        ...state,
        failureSignup: action.payload,
      };
    case Types.SUBSCRIPTION_IN_FAILURE:
      return {
        ...state,
        failureSubscription: action.payload,
      };
    case Types.LOGOUT:
      return produce(state, draft => {
        draft.token = null;
        draft.user = null;
      });
    case Types.SET_LOADING:
      return produce(state, draft => {
        draft.loading = action.payload;
      });
    case Types.SET_SUBSCRIPTION:
      return produce(state, draft => {
        draft.subscription = action.payload;
      });
    case Types.SET_USER:
      return produce(state, draft => {
        draft.user = action.payload;
      });
    default:
      return state;
  }
}

// Actions
export function signInRequest(email, password) {
  return {
    type: Types.SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signUpRequest(name, email, cpf, password) {
  return {
    type: Types.SIGN_UP_REQUEST,
    payload: { name, email, cpf, password },
  };
}

export function setUser(user) {
  return {
    type: Types.SET_USER,
    payload: user,
  };
}

export function signInSuccess(token, user) {
  return {
    type: Types.SIGN_IN_SUCCESS,
    payload: { token, user },
  };
}

export function signInFailure(message) {
  return {
    type: Types.SIGN_IN_FAILURE,
    payload: message,
  };
}

export function signUpFailure(message) {
  return {
    type: Types.SIGN_UP_FAILURE,
    payload: message,
  };
}

export function logoutUser() {
  return {
    type: Types.LOGOUT,
  };
}

export function setLoading(status) {
  return {
    type: Types.SET_LOADING,
    payload: status,
  };
}

export function setSubscription(subscription) {
  return {
    type: Types.SET_SUBSCRIPTION,
    payload: subscription,
  };
}

export function requestSubscription(data) {
  return {
    type: Types.SUBSCRIPTION_IN_REQUEST,
    payload: data,
  };
}

export function cancelSubscription() {
  console.log('....');
  return {
    type: Types.SUBSCRIPTION_CANCEL,
  };
}

export function setFailuleSubscription(message) {
  return {
    type: Types.SUBSCRIPTION_IN_FAILURE,
    payload: message,
  };
}
