import React, { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setLoading } from '../store/modules/content';
import api from './api';

export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const [profile, setProfile] = useState(false);
  const [data, setData] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(false);

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    async function search() {
      try {
        setLoadingProfile(true);
        const res = await api.get(`/user`, headers);
        setData(res.data);
        setLoadingProfile(false);
        return res.data;
      } catch (error) {
        setLoadingProfile(false);
        return error;
      }
    }
    search();
  }, [token]);

  return (
    <ProfileContext.Provider
      value={[
        profile,
        setProfile,
        data,
        setData,
        loadingProfile,
        setLoadingProfile,
      ]}
    >
      {children}
    </ProfileContext.Provider>
  );
}
