import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Content, ContentThankyou } from './styles';
import Header from '../../components/Header';
import { validateSubscription } from '../../store/modules/auth/sagas';
import api from '../../services/api';
import history from '../../services/history';
import { setUser } from '../../store/modules/auth';

export default function SubscriptionConfirmation() {
  const { token } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      validateSubscription();
    }, 2000);
  }, []);

  async function validateSubscription() {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await api.get(
        'subscriptions/validateSubscription',
        headers
      );
      dispatch(setUser(response.data.user));
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        validateSubscription();
      }, 2000);
    }
  }

  return (
    <>
      <Header />
      <Container>
        <ContentThankyou>Muito obrigado!</ContentThankyou>
        <Content>
          Estamos aguardando confirmação da sua assinatura!
          <p>Por favor aguarde, isso pode levar alguns minutos...</p>
        </Content>
      </Container>
    </>
  );
}
