import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoginContainer, SignupLink, SignupDiv } from './styles';
import Logo from '../../components/Logo';
import Header from '../../components/Header';
import {
  signInRequest,
  signInFailure,
  setLoading,
} from '../../store/modules/auth';

export default function Main() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkLoading, setCheckLoading] = useState(false);
  const { failure, loading } = useSelector(state => state.auth);

  async function sendForm(data) {
    dispatch(signInRequest(email, password));
  }

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(signInFailure(false));
  }, [dispatch]);

  function _renderFailureMessage() {
    if (failure === false) {
      return null;
    }
    dispatch(setLoading(false));
    return <Alert variant="danger">{failure}</Alert>;
  }

  return (
    <>
      <Header />
      <Container>
        <Row className="justify-content-md-center">
          <Col md="6" className="mt-5">
            <LoginContainer>
              <div className="center mb-5">
                <Logo />
              </div>

              <h5 className="center mb-3">Efetuar Login</h5>

              {_renderFailureMessage()}

              <form>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Senha</label>
                  <input
                    name="password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <button type="button" onClick={sendForm}>
                  {loading ? 'Carregando...' : 'Fazer Login'}
                </button>
              </form>
            </LoginContainer>
            <SignupDiv>
              <Link to="signup">Cadastre-se</Link>
            </SignupDiv>
          </Col>
        </Row>
      </Container>
    </>
  );
}
