import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  font-size: 25px;
  color: #555;
  padding: 10px;
`;

export const LoginContainer = styled.div`
  background: #f9f9f9;
  padding: 30px;
  margin: auto;
  border-radius: 5px;
`;

export const SignupDiv = styled.div`
  text-align: center;
  padding: 10px;
`;

export const SignupLink = styled.a`
  text-align: center;
`;
