import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  Types,
  signInFailure,
  signInSuccess,
  setLoading,
  signUpFailure,
  setFailuleSubscription,
  setUser,
} from '.';
import api from '../../../services/api';
import history from '../../../services/history';

export function* signIn({ payload }) {
  const { loading } = yield select(state => state.auth);
  const { email, password } = payload;
  yield put(setLoading(true));
  try {
    const response = yield call(api.post, 'signin', {
      email,
      password,
    });
    const { token, user } = response.data;
    yield put(setLoading(false));
    yield put(signInSuccess(token, user));
    history.push('/dashboard');
  } catch (err) {
    yield setLoading(false);
    yield put(signInFailure('Login e senha não confere.'));
  }
}

export function* signUp({ payload }) {
  const { name, email, cpf, password } = payload;
  yield put(setLoading(true));
  try {
    const response = yield call(api.post, 'signup', {
      name,
      email,
      password,
      cpf,
    });
    const { token, user } = response.data;
    yield put(signInSuccess(token, user));
    yield put(setLoading(false));
    history.push('/dashboard');
  } catch (err) {
    yield put(setLoading(false));
    yield put(signUpFailure(err.response.data.message));
  }
}

export function* validateSubscription({ payload }) {
  const { token, user } = yield select(state => state.auth);
  if (user.userable == null || user.userable.subscription == null) {
    history.push('/subscription');
  }
}

export function* cancelSubscription() {
  console.log('cancelando...');
  const { token, user } = yield select(state => state.auth);

  yield put(setLoading(true));
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log(headers);
    const response = yield call(api.get, 'subscriptions/cancel', headers);
    const newUser = response.data.user;
    yield put(setUser(newUser));
    yield put(setLoading(false));
    history.push('/subscriptionCanceled');
  } catch (err) {
    yield put(setLoading(false));
    alert(err.response.data.message);
  }
}

export function* requestSubscription({ payload }) {
  const { token } = yield select(state => state.auth);

  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = yield call(
      api.post,
      'subscriptions',
      { ...payload },
      headers
    );
    const newUser = response.data.user;
    yield put(setUser(newUser));
    yield put(setLoading(false));
    history.push('/subscriptionConfirmation');
  } catch (err) {
    yield put(setLoading(false));
    yield put(setFailuleSubscription(err.response.data.message));
    console.log('Error ao enviar assinatura', err);
  }
}

export default all([
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_UP_REQUEST, signUp),
  takeLatest(Types.VALIDATE_SUBSCRIPTION, validateSubscription),
  takeLatest(Types.SUBSCRIPTION_IN_REQUEST, requestSubscription),
  takeLatest(Types.SUBSCRIPTION_CANCEL, cancelSubscription),
]);
