import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    background: #FFF;
  }

  body, input, button {
    font-family: 'Roboto' !important;
    font-size: 15px !important;
  }

  label {
    font-size: 14px;
    color: #555;
    font-weight: bold;
  }

  input {
    background: #FFF;
    display: block;
    padding: 8px;
    border: 1px solid #DDD;
    width: 100%;
  }

  button {
    background: #B0DA69;
    padding: 12px;
    border: none;
    border-radius: 8px !important;
    cursor: pointer;
    font-weight:bold;
    transition-duration: 0.2s;
  }

  a {
    cursor: pointer;
    color: #444 !important;
  }

  a:hover{
    color: #93B758 !important;
  }

  button:hover {
    background: #99BE5B;
  }

  .form-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .center {
    text-align: center;
  }

  .mb-1 {
    margin-bottom: 5px;
  }
  .mb-2 {
    margin-bottom: 10px;
  }

  .mb-3 {
    margin-bottom: 20px;
  }

  .mb-4 {
    margin-bottom: 40px;
  }
  .mb-5 {
    margin-bottom: 80px;
  }

`;
