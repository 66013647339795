import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  Types,
  setCategories,
  setLoading,
  setProducts,
  setRecents,
  setHighlights,
  setFavorites,
  setUseCategory,
  setCategory,
} from '.';
import api from '../../../services/api';
import { validateSubscription } from '../auth/sagas';

export function* loadInitialContent() {
  const { token } = yield select(state => state.auth);

  yield put({ type: Types.VALIDATE_SUBSCRIPTION });

  yield put(setLoading(true));
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resCategories = yield call(api.get, 'categories', headers);
    yield put(setCategories(resCategories.data));

    const resShowcases = yield call(api.get, 'products/showcases', headers);
    yield put(setProducts(resShowcases.data));

    const resHighlights = yield call(api.get, 'products/highlights', headers);
    yield put(setHighlights(resHighlights.data));

    yield put(setUseCategory(true));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
  }
}

export function* loadRecentsContent() {
  const { token } = yield select(state => state.auth);

  yield put(setLoading(true));
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resRecents = yield call(api.get, 'products/recents', headers);
    yield put(setRecents(resRecents.data));

    yield put(setUseCategory(false));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
  }
}
export function* loadFavoritesContent() {
  const { token } = yield select(state => state.auth);

  yield put(setLoading(true));
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resFavorites = yield call(api.get, 'favorites', headers);
    yield put(setFavorites(resFavorites.data));

    yield put(setUseCategory(false));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
  }
}
export function* loadCategoryContent({ payload }) {
  const { token } = yield select(state => state.auth);

  yield put(setLoading(true));
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resCategory = yield call(
      api.get,
      `products/category/${payload.id}`,
      headers
    );
    yield put(setCategory(resCategory.data));

    yield put(setUseCategory(true));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
  }
}

export default all([
  takeLatest(Types.LOAD_INITIAL_CONTENT, loadInitialContent),
  takeLatest(Types.LOAD_RECENTS_CONTENT, loadRecentsContent),
  takeLatest(Types.LOAD_FAVORITES_CONTENT, loadFavoritesContent),
  takeLatest(Types.LOAD_CATEGORY_CONTENT, loadCategoryContent),
]);
