import produce from 'immer';

export const Types = {
  LOAD_INITIAL_CONTENT: '@content/LOAD_INITIAL_CONTENT',
  LOAD_RECENTS_CONTENT: '@content/LOAD_RECENTS_CONTENT',
  LOAD_FAVORITES_CONTENT: '@content/LOAD_FAVORITES_CONTENT',
  LOAD_CATEGORY_CONTENT: '@content/LOAD_CATEGORY_CONTENT',
  SET_CATEGORIES: '@content/SET_CATEGORIES',
  SET_USECATEGORY: '@content/SET_USECATEGORY',
  SET_CATEGORY: '@content/CATEGORY',
  SET_PRODUCTS: '@content/SET_PRODUCTS',
  SET_LOADING: '@content/SET_LOADING',
  SET_PRODUCT: '@content/SET_PRODUCT',
  SET_RECENTS: '@content/SET_RECENTS',
  SET_CONTENT: '@content/SET_CONTENT',
  SET_HIGHLIGHTS: '@content/SET_HIGHLIGHTS',
  SET_FAVORITES: '@content/SET_FAVORITES',
  VALIDATE_SUBSCRIPTION: '@auth/VALIDATE_SUBSCRIPTION',
};

const INITIAL_STATE = {
  searchResult: [],
  categories: [],
  category: [],
  products: [],
  recents: [],
  highlights: [],
  favorites: [],
  useCategory: false,
  loading: false,
  product: null,
  content: null,
};

export default function content(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_CATEGORIES:
      return produce(state, draft => {
        draft.categories = action.payload;
      });
    case Types.SET_LOADING:
      return produce(state, draft => {
        draft.loading = action.payload;
      });
    case Types.SET_PRODUCT:
      return produce(state, draft => {
        draft.product = action.payload;
      });
    case Types.SET_CONTENT:
      return produce(state, draft => {
        draft.content = action.payload;
      });
    case Types.SET_RECENTS:
      return produce(state, draft => {
        draft.recents = action.payload;
      });
    case Types.SET_PRODUCTS:
      return produce(state, draft => {
        draft.products = action.payload;
      });
    case Types.SET_HIGHLIGHTS:
      return produce(state, draft => {
        draft.highlights = action.payload;
      });
    case Types.SET_FAVORITES:
      return produce(state, draft => {
        draft.favorites = action.payload;
      });
    case Types.SET_CATEGORY:
      return produce(state, draft => {
        draft.category = action.payload;
      });
    case Types.SET_USECATEGORY:
      return produce(state, draft => {
        draft.useCategory = action.payload;
      });
    default:
      return state;
  }
}

export function setCategories(categories) {
  return {
    type: Types.SET_CATEGORIES,
    payload: categories,
  };
}

export function setProducts(products) {
  return {
    type: Types.SET_PRODUCTS,
    payload: products,
  };
}
export function setRecents(recents) {
  return {
    type: Types.SET_RECENTS,
    payload: recents,
  };
}

export function setLoading(loading) {
  return {
    type: Types.SET_LOADING,
    payload: loading,
  };
}

export function setProduct(product) {
  return {
    type: Types.SET_PRODUCT,
    payload: product,
  };
}
export function setHighlights(highlight) {
  return {
    type: Types.SET_HIGHLIGHTS,
    payload: highlight,
  };
}
export function setFavorites(favorite) {
  return {
    type: Types.SET_FAVORITES,
    payload: favorite,
  };
}
export function setContent(content) {
  return {
    type: Types.SET_CONTENT,
    payload: content,
  };
}
export function setCategory(cat) {
  return {
    type: Types.SET_CATEGORY,
    payload: cat,
  };
}
export function setUseCategory(useCategory) {
  return {
    type: Types.SET_USECATEGORIES,
    payload: useCategory,
  };
}
export function loadInitialContent() {
  return {
    type: Types.LOAD_INITIAL_CONTENT,
  };
}
export function loadRecentsContent() {
  return {
    type: Types.LOAD_RECENTS_CONTENT,
  };
}
export function loadFavoritesContent() {
  return {
    type: Types.LOAD_FAVORITES_CONTENT,
  };
}

export function loadCategoryContent(id) {
  return {
    type: Types.LOAD_CATEGORY_CONTENT,
    payload: { id },
  };
}
