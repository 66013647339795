import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { ProfileContext } from '../../services/profileContext';
import { HeaderContainer, ProfileImage, ProfileName, Profile } from './styles';
import Logo from '../Logo';
import imageProfile from '../../assets/img/profile-round.png';
import { logoutUser } from '../../store/modules/auth';
import history from '../../services/history';

export default function Header(props) {
  const [
    profile,
    setProfile,
    data,
    setData,
    loadingProfile,
    setLoadingProfile,
  ] = useContext(ProfileContext);
  const dispatch = useDispatch();

  function logout() {
    dispatch(logoutUser());
    return <Link to="/" />;
    // history.push('/');
  }

  return (
    <HeaderContainer>
      <Container>
        <Row>
          <Col sm="2" xs="5">
            <Logo />
          </Col>
          <Col sm={{ span: 3, offset: 7 }} xs="7">
            {data.email ? (
              <Profile>
                <ProfileName>
                  {data.name} <br />
                  <a href="#" onClick={logout}>
                    Sair
                  </a>
                </ProfileName>
                <ProfileImage>
                  <img src={imageProfile} alt="Profile" />
                </ProfileImage>
              </Profile>
            ) : null}
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  );
}
