import styled, { keyframes } from 'styled-components';

export const Container = styled.div``;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: row;
  img {
    height: 400px;
  }
  .info {
    margin-left: 20px;
    .edition {
      font-size: 23px;
    }
    .description {
      color: #4a4a4a;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 20px;
        cursor: pointer;
        :hover {
          color: #8f8f8f;
        }
      }
    }
  }
`;

export const EdictionsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 30px;
  a {
    color: unset;
    text-decoration: none !important;
  }
  .titleSection {
    font-size: 24px;
    font-weight: bold;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    overflow: hidden;
    border: none;
    padding: 5px;
    background: #f9f9f9;
    cursor: pointer;
    img {
      height: 200px;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-top: 10px;
      .edition {
        font-size: 23px;
      }
      .description {
        color: #4a4a4a;
      }
      .date {
        margin-top: -15px;
        color: #99be5b;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  color: red;
  font-size: 30px;
  position: absolute;
  margin-right: 20px;

  svg {
    color: #6f6f6e;
    opacity: 0.7;
    animation: ${rotate} 2s linear infinite;
  }
`;
