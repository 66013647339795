import styled, { keyframes } from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    p {
      position: initial;
      margin-bottom: 0px;
      color: #99be5b;
      font-weight: bold;
      font-size: 15px;
    }
  }
  .search {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-right: 22%;

    padding: 5px 5px;
    background-color: #f2f2f2;

    border-radius: 3px;

    -webkit-box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);
    -moz-box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);
    box-shadow: 0px 6px 5px 0px rgba(227, 227, 227, 1);

    input {
      padding: 0px 5px;
      background-color: transparent;
      border: none;
    }
  }
  margin-bottom: 30px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding-top: 10%; */
  color: red;
  font-size: 50px;

  svg {
    color: #6f6f6e;
    opacity: 0.7;
    animation: ${rotate} 2s linear infinite;
  }
`;
