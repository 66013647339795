import styled from 'styled-components';
import { textPrimaryColor, primaryColor } from '../../styles/vars';

export const HeaderContainer = styled.div`
  background: #f9f9f9;
  padding: 14px;
  box-shadow: 2px 2px 2px rgba(240, 240, 240, 1);
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  border-top: 5px solid ${primaryColor};
`;

export const ProfileName = styled.div`
  text-align: right;
  padding-top: 12px;
  padding-right: 5px;
  flex: 3;
  font-weight: bold;
  color: ${textPrimaryColor};
  font-size: 14px;
`;

export const ProfileImage = styled.div`
  flex: 1;
  margin: 5px;
  img {
    width: 50px;
  }
`;

export const Profile = styled.div`
  display: flex;
`;
