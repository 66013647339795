import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  font-size: 18px;
  color: #333;
  text-align: center;
  margin: 40px;
  font-weight: bold;

  p {
    color: #777;
    font-size: 15px;
    font-weight: 100;
    margin-top: 10px;
  }
`;

export const ContentThankyou = styled.div`
  font-size: 24px;
  color: #83ae56;
  text-align: center;
  margin: 40px;
  font-weight: bold;
`;
