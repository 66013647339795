import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { FaHeart, FaSpinner } from 'react-icons/fa';
import api from '../../services/api';
import { Container, ProductCard, EdictionsCard, Loading } from './styles';
import { saveFavorite, getFavorites } from '../../services/Favorites';

export default function Product(props) {
  const { token } = useSelector(state => state.auth);
  const [product, setProduct] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoritesList, setFavoritesList] = useState([]);
  const [productEditions, setProductEditions] = useState();
  const [titleProduct, setTitleProduct] = useState();
  const [read, setRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data } = props;

  function CheckIsFavorite() {
    favoritesList.map(id => {
      if (id == product.id) {
        setIsFavorite(true);
        return <></>;
      }
    });

    return <></>;
  }

  useEffect(() => {
    async function getFavoritesList() {
      try {
        const res = await getFavorites(token);
        const list = [];
        res.data.map(f => {
          list.push(f.content_id);
        });
        setFavoritesList(list);
        return res;
      } catch (error) {
        return error;
      }
    }
    async function loadDetail() {
      try {
        if (data.product_id) {
          // verificando se é um favorito (favoritos não tem referência ao produto)
          setProduct(data);
          setTitleProduct(data.name);
          return data;
        }
        const res = await api.get(`contents/${data.last_content_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProduct(res.data);
        setTitleProduct(res.data.product.name);
        getFavoritesList();

        return res;
      } catch (error) {
        return error;
      }
    }
    async function loadEdiction() {
      try {
        const res = await api.get(`products/${data.id}/contents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProductEditions(res.data);
        return res;
      } catch (error) {
        return error;
      }
    }

    loadDetail();
    loadEdiction();
  }, [data, token]);

  function formatDate(date) {
    if (date.length < 11) {
      return date;
    }
    const dateIso = parseISO(date);
    const formattedDate = format(dateIso, "dd'/'MM'/'yy");
    return formattedDate;
  }
  function onHandle(p) {
    setProduct(p);
  }
  async function favorite(id) {
    setLoading(true);
    try {
      const res = await saveFavorite(id, token);
      if (res.data.message === 'deleted') {
        setFavoritesList(res.data.favorites);
        setIsFavorite(false);
        setLoading(false);
      }
      if (res.data.message === 'added') {
        setIsFavorite(true);
        setLoading(false);
      }
      return res;
    } catch (error) {
      setLoading(true);
      return error;
    }
  }
  return (
    <>
      {!read ? (
        <>
          {product && (
            <Container>
              <ProductCard id="card-info">
                <img src={product.image_path} alt="" />
                <div className="info">
                  <p className="title">
                    <strong>{titleProduct}</strong>
                  </p>
                  <p className="edition">
                    {' '}
                    <strong>{product.name}</strong>{' '}
                  </p>
                  <p className="date">{formatDate(product.publish_start)}</p>
                  <p className="description">{product.description}</p>
                  <CheckIsFavorite />
                  <div className="actions">
                    {loading && (
                      <>
                        <Loading>
                          <FaSpinner />
                        </Loading>
                      </>
                    )}
                    {!data.product_id ? (
                      <FaHeart
                        size="30"
                        color={isFavorite ? 'red' : '#a0a0a0'}
                        onClick={() => favorite(product.id)}
                      />
                    ) : null}

                    <button type="button" onClick={() => setRead(true)}>
                      Começar a ler
                    </button>
                  </div>
                </div>
              </ProductCard>
              <EdictionsCard>
                {productEditions && (
                  <>
                    <p className="titleSection">Outras Edições</p>
                    {productEditions.map(p => (
                      <a href="#card-info" key={p.id}>
                        <div className="card" onClick={() => onHandle(p)}>
                          <img src={p.image_path} alt="" />
                          <div className="info">
                            <p className="title">
                              {' '}
                              <strong>{p.name}</strong>
                            </p>
                            <p className="date">
                              {formatDate(p.publish_start)}
                            </p>
                            <p className="description">{p.description}</p>
                          </div>
                        </div>
                      </a>
                    ))}
                  </>
                )}
              </EdictionsCard>
            </Container>
          )}
        </>
      ) : (
        <object
          width="100%"
          height="100%"
          type="application/pdf"
          data={`${product.file_path}`}
          id="pdf_content"
        >
          <p>Document load was not successful.</p>
        </object>
      )}
    </>
  );
}
