import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { Header, Loading } from './styles';
import { setActive } from '../../store/modules/active';
import api from '../../services/api';

import { SearchContext } from '../../services/searchContex';

export default function Products(props) {
  const [
    text,
    setText,
    searchResult,
    setSearchResult,
    loading,
    setLoading,
  ] = useContext(SearchContext);
  const dispatch = useDispatch();
  const { products } = props;
  const { activeRouter } = props;

  useEffect(() => {
    if (text.length > 2) {
      dispatch(setActive('Search'));
    }
  }, [dispatch, text]);

  return (
    <>
      <Header>
        <div className="title">
          <p>{activeRouter}</p>
        </div>
        <div className="search">
          <FaSearch />
          <input
            type="text"
            onChange={e => {
              setText(e.target.value);
            }}
            value={text}
          />
        </div>
      </Header>
      {loading && (
        <>
          <Loading>
            <FaSpinner />
          </Loading>
        </>
      )}
    </>
  );
}
