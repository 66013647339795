import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import {
  SubscriptionContainer,
  Content,
  PlansContainer,
  PlanValue,
} from './styles';
import Header from '../../components/Header';
import PlanList from '../../components/PlanList';
import api from '../../services/api';
import Mask from '../../components/CustomFields/Mask';
import {
  requestSubscription,
  setFailuleSubscription,
  setLoading,
} from '../../store/modules/auth';

const schema = Yup.object().shape({
  cep: Yup.string()
    .max(9, 'Máximo 9 dígitos')
    .required('CEP obrigatório'),
  endereco: Yup.string().required('Endereço obrigatório'),
  numero: Yup.string().required('Número obrigatório'),
  bairro: Yup.string().required('Bairro obrigatório'),
  uf: Yup.string().required('Digite o UF do seu estado'),
  cidade: Yup.string().required('Cidade obrigatória'),
  card_holder_name: Yup.string().required('Digite o nome do titular do cartão'),
  card_number: Yup.string().required('Digite o número do cartão'),
  card_month: Yup.string('Mês')
    .max(2)
    .required('Mês de validade'),
  card_year: Yup.string('Ano')
    .max(4, 'Ano Validade')
    .required('Ano de validade'),
  card_cvv: Yup.string()
    .max(4, 'CVV')
    .required('CVV'),
});

export default function Subscription() {
  const dispatch = useDispatch();
  const { failureSubscription, loading, token } = useSelector(
    state => state.auth
  );
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [brand, setBrand] = useState(null);
  const [senderHash, setSenderHash] = useState(null);

  function _renderFailureMessage() {
    if (failureSubscription === false) {
      return null;
    }

    return <Alert variant="danger">{failureSubscription}</Alert>;
  }

  useEffect(() => {
    async function loadingPlans() {
      try {
        const response = await api.get('plans', {
          headers: { Authorization: `Bearer ${token}` },
        });
        response.data.map(plan => {
          const getInfo = {
            id: plan.id,
            name: plan.name,
            value: plan.value,
            description: plan.description,
            selected: false,
          };
          setSelectedPlan(response.data[0].id);
        });
        setPlans(response.data);
      } catch (err) {}
    }
    async function loadPagSeguroToken() {
      try {
        const pagSeguroToken = await api.get('subscriptions/sessionId', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await window.PagSeguroDirectPayment.setSessionId(pagSeguroToken.data);

        const senderHashCode = await window.PagSeguroDirectPayment.getSenderHash();
        setSenderHash(senderHashCode);
      } catch (err) {}
    }
    loadingPlans();
    loadPagSeguroToken();
    dispatch(setFailuleSubscription(false));
  }, [dispatch, token]);

  function getCardHash(
    cardNumber,
    brand,
    cvv,
    expirationMonth,
    expirationYear
  ) {
    return new Promise((resolve, reject) => {
      window.PagSeguroDirectPayment.createCardToken({
        cardNumber,
        brand,
        cvv,
        expirationMonth,
        expirationYear,
        success(response) {
          resolve(response.card.token);
        },
        error(response) {
          const errorCodes = Object.keys(response.errors);
          reject(errorCodes[0]);
        },
        complete(response) {},
      });
    });
  }

  function getCardBrand(cardNumber) {
    const firstNumbers = cardNumber.substr(0, 6);
    window.PagSeguroDirectPayment.getBrand({
      cardBin: firstNumbers,
      success(response) {
        setBrand(response.brand.name);
      },
      error(response) {},
    });
  }

  function getPureCardNumber(value) {
    const findToReplace = new RegExp('_| ', 'g');
    return value.replace(findToReplace, '');
  }

  function changeCard(card) {
    const cardNumber = getPureCardNumber(card);
    if (cardNumber.length > 6) {
      getCardBrand(cardNumber);
    }
  }

  function selectThisPlan(planId) {
    setSelectedPlan(planId);
  }

  async function sendForm(data) {
    dispatch(setLoading(true));
    data.plan_id = selectedPlan;

    try {
      const cardHash = await getCardHash(
        getPureCardNumber(data.card_number),
        brand,
        data.card_cvv,
        data.card_month,
        data.card_year
      );
      data.hash = cardHash;
      data.senderHash = senderHash;
      await dispatch(requestSubscription(data));
    } catch (err) {
      dispatch(setLoading(false));
      setFailuleSubscription(err);
    }
  }

  function renderPlans() {
    if (plans.length > 0) {
      return (
        <>
          {plans.map(plan => (
            <PlanList
              active={selectedPlan}
              plan={plan}
              getPlan={p => selectThisPlan(p)}
            />
          ))}
        </>
      );
    }
    return null;
  }

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Form schema={schema} onSubmit={sendForm}>
            <Row className="justify-content-md-center">
              <Col md="12">
                <h5 className="center mb-3">Faça sua Assinatura</h5>
                {_renderFailureMessage()}
              </Col>

              <Col md="6">
                <SubscriptionContainer>
                  {renderPlans()}
                  <h4 mt="5">Endereço de Cobrança</h4>
                  <div className="form-group">
                    <label>CEP</label>
                    <Mask inputMask="99999-999" name="cep" />
                  </div>
                  <div className="form-group">
                    <label>Endereço</label>
                    <Input name="endereco" />
                  </div>
                  <Row>
                    <Col md="4">
                      <div className="form-group">
                        <label>Número</label>
                        <Input name="numero" />
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="form-group">
                        <label>Complemento</label>
                        <Input name="complemento" />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <label>Bairro</label>
                    <Input name="bairro" />
                  </div>
                  <Row>
                    <Col md="3">
                      <div className="form-group">
                        <label>UF</label>
                        <Input name="uf" maxLength="2" />
                      </div>
                    </Col>
                    <Col md="9">
                      <div className="form-group">
                        <label>Cidade</label>
                        <Input name="cidade" />
                      </div>
                    </Col>
                  </Row>
                </SubscriptionContainer>
              </Col>
              <Col md="6">
                <SubscriptionContainer>
                  <h4>Informações de Pagamento</h4>
                  <div className="form-group">
                    <label>Títular do Cartão</label>
                    <Input name="card_holder_name" />
                  </div>
                  <div className="form-group">
                    <label>Número do Cartão</label>
                    <Mask
                      onChange={changeCard}
                      inputMask="9999 9999 9999 9999"
                      name="card_number"
                    />
                  </div>
                  <Row>
                    <Col md="3">
                      <div className="form-group">
                        <label>Mês</label>
                        <Mask inputMask="99" name="card_month" maxLength="12" />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="form-group">
                        <label>Ano</label>
                        <Mask inputMask="9999" name="card_year" maxLength="4" />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label>CVV</label>
                        <Input name="card_cvv" maxLength="4" />
                      </div>
                    </Col>
                  </Row>{' '}
                  <button type="submit" disabled={loading}>
                    {loading
                      ? 'Enviando assinatura...'
                      : 'Completar Assinatura'}
                  </button>
                </SubscriptionContainer>
              </Col>
            </Row>
          </Form>
        </Content>
      </Container>
    </>
  );
}
