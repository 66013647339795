import React, { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setLoading } from '../store/modules/content';
import api from './api';

export const SearchContext = createContext();

export function SearchProvider(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const [text, setText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    async function search() {
      if (text.length > 2) {
        try {
          setLoading(true);
          const res = await api.get(`/products/search/${text}`, headers);
          setSearchResult(res.data);
          setLoading(false);
          return res.data;
        } catch (error) {
          setLoading(false);
          return error;
        }
      }
      return 0;
    }
    search();
  }, [dispatch, text, token]);

  return (
    <SearchContext.Provider
      value={[
        text,
        setText,
        searchResult,
        setSearchResult,
        loading,
        setLoading,
      ]}
    >
      {children}
    </SearchContext.Provider>
  );
}
