import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Content, ContentThankyou } from './styles';
import Header from '../../components/Header';

export default function SubscriptionCanceled() {
  return (
    <>
      <Header />
      <Container>
        <ContentThankyou>Assinatura Cancelada!</ContentThankyou>
        <Content>Que pena, esperamos que volte em breve!</Content>
      </Container>
    </>
  );
}
