import React, { useState, useEffect } from 'react';

import { Container, PlansContainer, PlanValue } from './styles';

export default function PlanList(props) {
  const [selected, setSelected] = useState(null);
  const { active, plan, getPlan } = props;

  useEffect(() => {
    setSelected(plan.id);
  });

  function onHandle() {
    getPlan(plan.id);
    setSelected(plan.id);
  }

  function setClass() {
    if (selected != null) {
      if (selected == active) {
        return 'activePlan';
      }
      return 'noActivePlan';
    }
    return null;
  }
  return (
    <Container>
      <PlansContainer
        className={setClass()}
        selectedPlan={selected}
        id={`plan-${plan.id}`}
        key={plan.id}
        onClick={() => onHandle()}
      >
        <h5>{plan.name}</h5>
        <PlanValue className="plan-active">
          R$ {plan.value.replace('.', ',')}
        </PlanValue>
        <p>{plan.description}</p>
      </PlansContainer>
    </Container>
  );
}
