import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 25px;
  color: #555;
  padding: 10px;
`;

export const Content = styled.div`
  form {
    span {
      font-size: 12px;
      color: #ff0000;
    }
  }
`;

export const SignupContainer = styled.div`
  background: #f9f9f9;
  padding: 20px;
  margin: auto;
  border-radius: 5px;
`;
