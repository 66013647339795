import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import api from '../../services/api';
import { ProfileContext } from '../../services/profileContext';
import imageProfile from '../../assets/img/profile.jpg';

import {
  Container,
  Logo,
  Info,
  PlanValue,
  PlansContainer,
  PlansInfo,
} from './styles';
import { cancelSubscription } from '../../store/modules/auth';
import moment from 'moment';

export default function Profile() {
  const [changePass, setChangePass] = useState(false);
  const [message, setMessage] = useState('');
  const [userPlan, setUserPlan] = useState('');
  const [userSubscription, setUserSubscription] = useState('');

  const [
    profile,
    setProfile,
    data,
    setData,
    loadingProfile,
    setLoadingProfile,
  ] = useContext(ProfileContext);
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  const schema = Yup.object().shape({
    old_password: Yup.string()
      .required('Senha obrigatória')
      .min(6, 'Mínimo de 6 caracteres'),
    new_password: Yup.string()
      .required('Senha obrigatória')
      .min(6, 'Mínimo de 6 caracteres'),
    new_password2: Yup.string()
      .required('Confirmação de senha obrigatória')
      .min(6, 'Mínimo de 6 caracteres. ')
      .oneOf([Yup.ref('new_password'), null], 'Senhas não conferem'),
  });

  useEffect(() => {
    setUserPlan(data.userable.subscription.plan);
    setUserSubscription(data.userable.subscription);
  });

  function confirmCancel() {
    console.log('Cancelar');
    dispatch(cancelSubscription());
  }

  async function updatePassword(passData) {
    const { old_password, new_password, new_password2 } = passData;
    try {
      const res = await api.put(
        '/user/updatePassword',
        {
          new_password,
          new_password2,
          old_password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status == 200) {
        setChangePass(false);
        setMessage('updated');
      }
      return res;
    } catch (error) {
      setMessage('failed');
      return error;
    }
  }
  console.log(userSubscription);
  return (
    <>
      <Container>
        <Logo>
          {!data.avatar ? (
            <img src={imageProfile} width="100%" alt="" />
          ) : (
            <img src={data.avatar} width="100%" alt="" />
          )}
        </Logo>
        <Info>
          <p>{data.name}</p>
          <p>{data.email}</p>
        </Info>
        {!changePass ? (
          <>
            <button
              type="button"
              className="changePass"
              onClick={() => setChangePass(true)}
            >
              Alterar Senha
            </button>
            {message === 'updated' && (
              <Alert
                variant="success"
                onLoad={setTimeout(() => setMessage(''), 3000)}
              >
                Senha Alterada
              </Alert>
            )}
            {userPlan && userSubscription.active && (
              <PlansInfo>
                <PlansContainer>
                  <h5>{userPlan.name}</h5>
                  <PlanValue className="plan-active">
                    R$ {userPlan.value.replace('.', ',')}
                    <button
                      onClick={() => confirmCancel()}
                      type="button"
                      className="cancel"
                    >
                      Cancelar
                    </button>
                  </PlanValue>
                  <p>
                    {userPlan.description} - {userSubscription.type}
                  </p>
                  <p>
                    <strong>Início</strong>:{' '}
                    {moment(userSubscription.start_at).format('DD/MM/YYYY')} -{' '}
                    <strong>Término</strong> :{' '}
                    {moment(userSubscription.end_at).format('DD/MM/YYYY')}
                  </p>
                </PlansContainer>
              </PlansInfo>
            )}
          </>
        ) : (
          <Form schema={schema} onSubmit={updatePassword}>
            {message === 'failed' && (
              <Alert variant="danger">
                Verifique se a senha atual está correta
              </Alert>
            )}

            <div className="form-group">
              <label>Senha</label>
              <Input name="old_password" type="password" />
            </div>
            <div className="form-group">
              <label>Nova Senha</label>
              <Input name="new_password" type="password" />
            </div>
            <div className="form-group">
              <label>Confirmar Senha</label>
              <Input name="new_password2" type="password" />
            </div>
            <div className="buttonAction">
              <button type="submit">Alterar senha</button>
              <button
                type="button"
                className="cancel"
                onClick={() => setChangePass(false)}
              >
                Cancelar
              </button>
            </div>
          </Form>
        )}
      </Container>
    </>
  );
}
