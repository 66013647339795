import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListProducts, CardItem } from './styles';
import Product from '../Product';

export default function Products(props) {
  const dispatch = useDispatch();
  const { products } = props;
  const [productList, setProductList] = useState([]);
  const { activeRouter } = props;
  const [text, setText] = useState('');
  const [detail, setDetail] = useState(false);
  const [selected, setSelected] = useState();
  const { searchResult } = useSelector(state => state.content);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  function onHandle(product) {
    setSelected(product);
    setDetail(true);
  }

  return (
    <>
      {!detail ? (
        <>
          {productList ? (
            <>
              {activeRouter === 'Favoritos' ? (
                <ListProducts>
                  {productList.map(p => (
                    <>
                      {p.content ? (
                        <CardItem
                          key={p.id}
                          onClick={() => onHandle(p.content)}
                        >
                          <img src={p.content.image_path} alt="" />
                          <p>{p.content.name}</p>
                          <span>{p.content.publish_start}</span>
                        </CardItem>
                      ) : null}
                    </>
                  ))}
                </ListProducts>
              ) : (
                <ListProducts>
                  {productList &&
                    productList.map(product => {
                      return (
                        <CardItem
                          key={product.id}
                          onClick={() => onHandle(product)}
                        >
                          <img
                            src={
                              !product.image_path
                                ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTP5obogXPAyjX68SLW5ZRZ1Q97ij8Ev6UvGSfMWrORVVLUaOmi'
                                : product.image_path
                            }
                            alt=""
                          />

                          <p>{product.name}</p>
                          <span>{product.publish_start}</span>
                        </CardItem>
                      );
                    })}
                </ListProducts>
              )}
            </>
          ) : null}
        </>
      ) : (
        <Product data={selected} />
      )}
    </>
  );
}
