import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  .activePlan {
    background: white !important;
    border: 3px solid #99be5b;
  }
  .noActivePlan {
    background: white;
  }
`;

export const PlansContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;

  p {
    font-size: 14px;
    color: #555;
    margin-bottom: 0px;
  }
`;

export const PlanValue = styled.div`
  font-size: 17px;
  color: green;
  font-weight: bold;
`;
