import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .alert-success {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 330px;
    text-align: center;
  }
  form {
    width: 100%;
    span {
      font-size: 12px;
      color: #ff0000;
    }
  }
  .buttonAction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      color: black;
    }
    .cancel {
      color: white;
      background: #818286;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 150px;
  margin-bottom: 20px;
  img {
    padding: 5px 5px;
    border-radius: 50%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: gray;
  }
`;

export const PlansInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
  border-radius: 4px;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    color: #555;
    margin-bottom: 0px;
  }
`;

export const PlanValue = styled.div`
  font-size: 17px;
  color: green;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .cancel {
    background: red;
    color: white;
  }
`;
