import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';

import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import Search from '../../components/Search';
import Profile from '../../components/Profile';
import {
  loadInitialContent,
  loadRecentsContent,
  loadFavoritesContent,
  loadSearchResult,
} from '../../store/modules/content';
import { Loading } from './styles';
import Products from '../../components/Products';
import { SearchContext } from '../../services/searchContex';
import { ProfileContext } from '../../services/profileContext';

export default function Dashboard() {
  const dispatch = useDispatch();
  const { activeRouter } = useSelector(state => state.active);
  const [text, setText, searchResult, setSearchResult] = useContext(
    SearchContext
  );
  const [
    profile,
    setProfile,
    data,
    setData,
    loadingProfile,
    setLoadingProfile,
  ] = useContext(ProfileContext);
  const {
    loading,
    products,
    recents,
    highlights,
    favorites,
    category,
  } = useSelector(state => state.content);

  const magazines = highlights.filter(res => res.type === 'magazine');
  const books = highlights.filter(res => res.type === 'book');
  const newspapers = highlights.filter(res => res.type === 'newspaper');

  useEffect(() => {
    if (activeRouter === 'Perfil') {
      setProfile(true);
    }
    if (activeRouter === 'Destaques') {
      dispatch(loadInitialContent());
    }
    if (activeRouter === 'Recentes') {
      dispatch(loadRecentsContent());
    }
    if (activeRouter === 'Favoritos') {
      dispatch(loadFavoritesContent());
    }
  }, [activeRouter, dispatch, profile, setProfile]);

  function getActivatedRouter(active) {
    switch (active) {
      case 'Perfil':
        return 0;
      case 'Destaques':
        return products;
      case 'Recentes':
        return recents;
      case 'Revistas':
        return magazines;
      case 'Livros':
        return books;
      case 'Jornais':
        return newspapers;
      case 'Favoritos':
        return favorites;
      case 'Search':
        return searchResult;
      default:
        return category; // switch to products
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <>
          <Loading>
            <FaSpinner />
          </Loading>
        </>
      );
    }

    return (
      <>
        {profile ? (
          <Profile />
        ) : (
          <>
            <Search />
            <Products
              products={getActivatedRouter(activeRouter)}
              activeRouter={activeRouter}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="3">
            <SideMenu />
          </Col>
          <Col sm="9">{renderContent()}</Col>
        </Row>
      </Container>
    </>
  );
}
