import api from './api';

export const saveFavorite = (id, token) => {
  return api.put(
    `favorites/${id}/save`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getFavorites = token => {
  return api.get('favorites', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
