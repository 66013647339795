import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Content, SignupContainer } from './styles';
import Logo from '../../components/Logo';
import Header from '../../components/Header';
import {
  signUpRequest,
  signUpFailure,
  setLoading,
} from '../../store/modules/auth';
import Mask from '../../components/CustomFields/Mask';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('Email obrigatório'),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(6, 'Mínimo de 6 caracteres'),
  name: Yup.string().required('Nome obrigatório'),
  cpf: Yup.string().required('CPF obrigatório.'),
  confirmPassword: Yup.string()
    .required('Confirmação de senha obrigatória')
    .min(6, 'Mínimo de 6 caracteres. ')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});

export default function Main() {
  const [validCpf, setValidCpf] = useState(true);
  const dispatch = useDispatch();
  const { failureSignup, loading } = useSelector(state => state.auth);

  async function sendForm(data) {
    const { name, email, cpf, password } = data;
    for (let i = 0; i <= cpf.length; i++) {
      if (cpf.charAt(i) === '_') {
        return setValidCpf(false);
      }
    }
    return dispatch(signUpRequest(name, email, cpf, password));
  }

  useEffect(() => {
    dispatch(signUpFailure(false));
    dispatch(setLoading(false));
  }, [dispatch]);

  function _renderFailureMessage() {
    if (failureSignup === false) {
      return null;
    }

    return <Alert variant="danger">{failureSignup}</Alert>;
  }
  function onHandleCpf(e) {
    setValidCpf(true);
  }
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Row className="justify-content-md-center">
            <Col md="6" className="mt-5">
              <SignupContainer>
                <div className="center mb-5">
                  <Logo />
                </div>

                <h5 className="center mb-3">Cadastre-se no BoraLer</h5>

                {_renderFailureMessage()}

                <Form schema={schema} onSubmit={sendForm}>
                  <div className="form-group">
                    <label>Nome</label>
                    <Input name="name" />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <Input name="email" />
                  </div>
                  <div className="form-group">
                    <label>CPF</label>
                    <Mask
                      name="cpf"
                      inputMask="999.999.999-99"
                      onChange={e => onHandleCpf(e)}
                    />
                    {!validCpf && <span>Mínimo de 11 caracteres</span>}
                  </div>
                  <div className="form-group">
                    <label>Senha</label>
                    <Input name="password" type="password" />
                  </div>
                  <div className="form-group">
                    <label>Confirmar Senha</label>
                    <Input name="confirmPassword" type="password" />
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Enviando cadastro...' : 'Fazer Cadastro'}
                  </button>
                </Form>
              </SignupContainer>
            </Col>
          </Row>
        </Content>
      </Container>
    </>
  );
}
