import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';

import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';
import GlobalStyles from './styles/global';

import 'bootstrap/dist/css/bootstrap.min.css';
import { store, persistor } from './store';

import { SearchProvider } from './services/searchContex';
import { ProfileProvider } from './services/profileContext';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SearchProvider>
          <ProfileProvider>
            <Router history={history}>
              <Routes />
              <GlobalStyles />
            </Router>
          </ProfileProvider>
        </SearchProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
